import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Paper,
  List,
  ListItem,
} from "@mui/material";
import PageTitle from '../components/PageTitle';
import { Helmet } from 'react-helmet-async';

const FirstResponders = () => {
  return (
    <Container>
    {/* Helmet for First Responders Page */}
    <Helmet>
        <title>First Responder Support - Northland Psychological Services</title>
        <meta
          name="description"
          content="NPS provides specialized mental health support and counseling for first responders in the Duluth MN area. Offering therapy for individuals and families."
        />
        <meta
          name="keywords"
          content="First Responder Support, Mental Health for First Responders, Counseling for First Responders, Trauma Counseling, PTSD Therapy, Northland Psychological Services, Hermantown MN"
        />
        <meta property="og:title" content="First Responder Support - Northland Psychological Services" />
        <meta
          property="og:description"
          content="Northland Psychological Services provides specialized mental health support and counseling for first responders in Hermantown, MN. Offering therapy for individuals and families."
        />
        <meta property="og:url" content="https://northlandpsychological.com/first-responders" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://northlandpsychological.com/first-responders" /> {/* Update to full URL */}
      </Helmet>
      <Grid container spacing={4} direction="column">
        {/* Header Section */}
        <Grid item xs={12}>
          <PageTitle
            title="First Responder Resource Center"
            description="Welcome to Northland Psychological Services' First Responders Page! Explore the page to see how Northland Psychological Services provides for our local first responders!"
          />
        </Grid>

        {/* Image and Description Section */}
        <Grid container item spacing={4} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Box sx={{ maxWidth: "100%", overflow: "hidden", borderRadius: "25px", textAlign: "center" }}>
              <img
                src="assets/images/northlandOfficePhotos/firstRespond/usa2.jpg"
                alt="First Responders equipment holder"
                title="First reponder equipment holder"

                style={{ width: "100%", height: "auto", borderRadius: "25px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={10} sx={{ p: 4, textAlign: "center", borderRadius: "20px", backgroundColor: "#4f5a5e" }}>
              <Typography variant='body1' color='common.white'>
                Northland Psychological maintains its clinic within the First Responder Resource Center.
                We partner with the Fraternal Order of Police: Lodge 9 and Reinforcement911 to create a first responder-friendly environment.
                Through Reinforcement911 501(c)(3), we are able to provide yearly wellness checks, crisis response, and free confidential sessions to our First Responder Personnel.
                Our goal is to help First Responders live longer, healthier, happier lives. Our current contracts are listed below.
                Please email reception@northlandpsychological.com for any inquiries or to make an appointment.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={18} sx={{ p: 2, mb: 2, textAlign: "center", borderRadius: "25px", backgroundColor:'#4f5a5e' }}>
            <Typography variant="h6" color="common.white">
              Donate Now
            </Typography>
            <Typography variant="body1" color="common.white">
              if you are interested in supporting the work we are able to do here at Northland Psychological Services, please consider donating to Reinforcement911 using the button below!
            </Typography>
            <Typography variant="body1" color="common.white">
              Thank you!
            </Typography>
            <a
                  href="https://reinforcement911.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white'
                      }
                    }}
                  >
                    Donate Now
                  </Button>
                </a>
            
          </Paper>
        </Grid>

        {/* Information Sections */}
        {[
          { title: "Minnesota Fire Fighters, Volunteers, and Employees", listItems: ["5 sessions covered by MN Fire Employee Assistance Program (EAP)"], imgSrc: "assets/images/FirstResponderLogos/MNFireLogo.png" },
          { title: "Duluth Fire", listItems: ["5 sessions covered by MN Fire Employee Assistance Program (EAP)", "4 sessions covered by Sand Creek Employee Assistance Program (EAP)"], imgSrc: "assets/images/FirstResponderLogos/DuluthFireLogo.jpg" },
          { title: "St. Louis County", listItems: ["Annual Wellness Checks", "5 Confidential Sessions"], imgSrc: "assets/images/FirstResponderLogos/St.LouisLogo.png" },
          { title: "Carlton County", listItems: ["Annual Wellness Checks", "3 Confidential Sessions"], imgSrc: "assets/images/FirstResponderLogos/CarltonCounty.png" },
          { title: "Muscatine Police Department", listItems: ["Annual Wellness Checks", "3 Confidential Sessions covered by Sand Creek Employee Assistance Program (EAP)"], imgSrc: "assets/images/FirstResponderLogos/MuscatineLogo.png" },
          { title: "Superior Police Department", listItems: ["More coming soon!"], imgSrc: "assets/images/FirstResponderLogos/SuperiorLogo.png" },
          { title: "Duluth Police Department", listItems: ["4 Sessions covered by Sand Creek Employee Assistance Program (EAP)"], imgSrc: "assets\\images\\updatedpics\\dpdPhoto.jpg" }
        ].map((section, index) => (
          <Grid container item spacing={4} justifyContent="center" alignItems="flex-start" key={index}>
            <Grid item xs={12}>
              <Paper elevation={10} sx={{ p: 4, textAlign: "center", borderRadius: "20px" }}>
                <Typography variant='h5'>{section.title}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={10} sx={{ p: 4, textAlign: "center", borderRadius: "20px", backgroundColor: '#c4d0b8' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                  <Box sx={{ p: 2, flex: 1 }}>
                    <List sx={{ listStyleType: 'disc', listStylePosition: 'inside', paddingLeft: 2 }}>
                      {section.listItems.map((item, idx) => (
                        <ListItem key={idx} sx={{ display: 'list-item', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  <Box sx={{ flex: 1, textAlign: 'center' }}>
                    <img
                      src={section.imgSrc}
                      alt={`${section.title} Logo`}
                      title={`${section.title} Logo`}
                      loading="lazy"
                      style={{ width: "100%", maxWidth: '150px', height: "auto", borderRadius: "25px" }}
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ))}

        {/* Schedule Section */}
        <Grid item xs={12}>
          <Paper elevation={18} sx={{ p: 4, textAlign: "center", borderRadius: "25px", backgroundColor: '#4f5a5e' }}>
            <Typography variant="h5" gutterBottom color="common.white">
              To schedule your Wellness Check or Confidential Session contact us:
            </Typography>
            <Typography variant="body3" color="common.white">
      Phone: 
      <a href="tel:218-729-6480" style={{ color: 'inherit', textDecoration: 'none', marginRight: '4px', marginLeft: '4px' }}>
        218-729-6480
      </a> 
      or 
      <br></br>
      <a href="mailto:reception@northlandpsychological.com" style={{ color: 'inherit', textDecoration: 'none', marginLeft: '4px' }}>
        reception@northlandpsychological.com
      </a>
    </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FirstResponders;
