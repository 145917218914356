import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HelmetProvider } from 'react-helmet-async';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: '"EB Garamond", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: '#c4d0b8',
      dark: '#000000',
      light: '#000000',
    },
    secondary: {
      main: '#e8e7e2',
      dark: '#000000',
      light: '#000000',
    },
    tertiary: {
      main: '#b7cbce',
      dark: '#000000',
      light: '#000000',
    },
    error: {
      main: '#ff0000',
      dark: '#000000',
      light: '#000000',
    },
    black: {
      main: '#000000',
      contrastText: '#ffffff',
      dark: '#000000',
    },
    white: {
      main: '#ffffff',
      contrastText: '#000000',
      dark: '#000000',
      light: '#000000',
    },
    dark: {
      main: '#343a40',
      contrastText: '#ffffff',
      dark: '#000000',
      light: '#000000',
    },
    success: {
      main: '#7AAD71',
    },
    background: {
      default: '#c4d0b8', // Set the default background color to primary.main
    },
  },
  spacing: 8, // Default spacing scale (8px)
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '16px', // Default padding for containers
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '16px', // Default padding for paper components
          margin: '16px', // Default margin for paper components
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: '8px 0', // Default margin for typography components
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '8px', // Default margin for button components
          padding: '8px 16px', // Default padding for button components
          '&:hover': {
            backgroundColor: '#D3D3D3', // Light grey
            color: '#000000',
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <HelmetProvider>
          <App />
        </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
